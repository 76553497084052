<template>
	<div class="page">
		<div class="relative bg-white content s1 flex mt-4">
			<van-search v-model="queryParams.groupName"
			            placeholder="搜索"
			            shape="round"
			            class="flex-1"
			            @update:model-value="debounceData"/>
			<van-button type="primary"
			            plain
			            round
			            size="small"
			            class="mr-3"
			            style="padding: 4px 13px"
			            @click="syncGroup()">刷新
			</van-button>
		</div>
		<div class="divider s1"></div>
		<van-list v-model:loading="loading"
		          :finished="finished"
		          finished-text=""
		          @load="getData">
			<div class="s1 p-card">
				<no-data v-if="list.length===0 && !loading "
				         title="暂无群聊，请刷新"
				         :show-button="false"/>
				<div v-for="(item,index) in list"
				     :key="index"
				     class="item flex"
				     @click="!item.isFollowRoom ? toMember(item.chatroomId) : ''">
					<div>
						<div class="avatar"><img :src="item.chatroomImgUrl"></div>
					</div>
					<div class="flex-1">
						<div>{{ item.chatroomName ? item.chatroomName : '未命名' }}</div>
						<div class="fs-12 color-gray mt-1"
						     v-if="item.isFollowRoom">此群已开启托管，不支持设置采集对象
						</div>
					</div>
					<div class="pl-2"
					     v-if="!item.isFollowRoom">
						<div class="p-icon-arrow"/>
					</div>
				</div>
			</div>
		</van-list>

		<div class="p-footer h-end">
			<button class="btn button"
			        @click="submit(0)">关闭
			</button>
			<!--      <button class="btn auto fill ml-3" @click="submit(1)">保存</button>-->
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { debounce } from '@/utils'
import { ApiSyncRobotChatRoom } from '@/apis/Common'
import { ApiGetChatrooms } from '@/apis/FollowLive'

export default {
	name: 'Index',
	data() {
		return {
			list: [],
			chatroomList: [],
			queryParams: {
				pageIndex: 1,
				pageSize: 10000,
				groupName: ''
			},
			loading: false,
			finished: false,
			debounceData: null

		}
	},
	computed: {
		...mapGetters([ 'robotInfo'])
	},
	created() {

	},
	mounted() {
		this.queryParams.robotWxId = this.robotInfo.robotWxId
		this.debounceData = debounce(this.getData, 1000)
	},
	methods: {
		async getData() {
			this.loading = true
			try {
				const res = await ApiGetChatrooms({ ...this.queryParams })
				this.list = res.dataList
				if (this.list.length >= res.recordCount) {
					this.finished = true
				}
				this.loading = false
			} catch (e) {
				this.loading = false
				this.finished = true
			}
		},

		toMember(chatroomId) {
			const _this = this
			_this.$router.push({
				path: '/selectMemberGather',
				query: { chatroomId: chatroomId }
			})
		},
		async syncGroup() {
			await ApiSyncRobotChatRoom( this.robotInfo.robotWxId)
			this.$Toast('刷新成功')
			await this.getData()

		},
		submit(n) {
			this.$router.go(-1)
		}
	}
}
</script>

<style scoped lang="scss">
@import "index";
</style>
